<template>
  <div>
    <van-form>
      <div class="body">
        <van-field
          v-model="form.username"
          name="username"
          label="姓名"
          placeholder="请输入您的姓名"
          left-icon="contact"
        >
          <template #left-icon>
            <img src="@/assets/img/patient/name.png" class="left-icon" alt="" />
          </template>
        </van-field>
        <van-field
          v-model="form.cardType.text"
          readonly
          clickable
          name="cardType"
          label="证件类型"
          left-icon="credit-pay"
          @click="showPicker = true"
        >
          <template #left-icon>
            <img
              src="@/assets/img/patient/idType.png"
              class="left-icon"
              alt=""
            />
          </template>
        </van-field>
        <van-popup v-model="showPicker" round position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @cancel="showPicker = false"
            @confirm="onConfirm"
            :default-index="0"
          />
        </van-popup>
        <van-field
          v-model="form.cardNumber"
          type="number"
          name="cardNumber"
          label="证件号码"
          left-icon="debit-pay"
          placeholder="请输入您的证件号"
          @touchstart.native.stop="show = true"
        >
          <template #left-icon>
            <img
              src="@/assets/img/patient/idNumber.png"
              class="left-icon"
              alt=""
            />
          </template>
        </van-field>
        <van-field
          v-model="form.phone"
          type="number"
          name="phone"
          label="手机号"
          left-icon="debit-pay"
          placeholder="请输入您的手机号"
          @touchstart.native.stop="show = true"
        >
          <template #left-icon>
            <img
              src="@/assets/img/patient/phone.png"
              class="left-icon"
              alt=""
            />
          </template>
        </van-field>
      </div>
      <van-button @click="selectData" class="selectJzCard" block type="info"
        >查询就诊卡</van-button
      >
    </van-form>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      form: {
        username: "",
        cardType: { key: "01", text: "居民身份证" },
        cardNumber: "",
        phone: "",
      },
      showPicker: false,
      columns: [
        { key: "01", text: "居民身份证" },
        { key: "02", text: "居民户口簿" },
        { key: "03", text: "护照" },
        { key: "04", text: "军官证" },
        { key: "05", text: "驾驶证" },
        { key: "06", text: "港澳居民来往内地通行证" },
        { key: "07", text: "台湾居民来往内地通行证" },
        { key: "08", text: " 出生医学证明" },
        { key: "09", text: "医保卡" },
        { key: "10", text: "就诊卡" },
        { key: "11", text: "电子健康卡" },
        { key: "12", text: "新生儿证件（仅山东卡管、广东卡管支持）" },
        { key: "15", text: "外国人永久居住证" },
        { key: "99", text: "其他法定有效证件" },
      ],
      show: false,
    };
  },
  methods: {
    selectData() {
      if (this.form.username === "") {
        Toast({ message: "请填写姓名！" });
        return;
      }
      if (this.form.cardNumber === "") {
        Toast({ message: "请填写身份证号！" });
        return;
      }
      if (!this.validator(this.form.cardNumber)) {
        Toast({ message: "请确认身份证号是否正确！" });
        return;
      }
      if (this.form.phone === "") {
        Toast({ message: "请填写联系方式！" });
        return;
      }
      if (!this.phoneValid(this.form.phone)) {
        Toast({ message: "请确认联系电话是否正确！" });
        return;
      }
      let postData = {
        name: this.form.username,
        idType: this.form.cardType.key,
        idNumber: this.form.cardNumber,
        phone: this.form.phone,
      };
      this.$http
        .get("/api/search/cardinfo", postData)
        .then((res) => {
          if (res.status == 200) {
            this.$store.commit("SetUserInfo", res.data);
            window.sessionStorage.setItem("userInfo", JSON.stringify(res.data));
            let cardItem = res.data;
            cardItem.deafult = false;
            window.sessionStorage.setItem(
              "card_cardItem",
              JSON.stringify(cardItem)
            );
            this.$router.push({
              path: "/bindPatient",
              query: {
                idType: this.form.cardType.text,
              },
            });
          } else {
            Toast.fail({ message: "查询失败！" });
          }
        })
        .catch((err) => {
          Toast.fail({ message: err.response.data });
          console.log(err);
        });
    },
    onConfirm(value) {
      this.form.cardType = value;
      this.showPicker = false;
    },
    // 身份证号校验
    validator(val) {
      return /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(
        val
      );
    },
    // 手机号验证
    phoneValid(val) {
      return /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/.test(
        val
      );
    },
  },
};
</script>

<style scoped>
.body {
  background-color: #fff;
  margin: 0.3rem;
  -webkit-box-shadow: #e6e6e6 0px 0px 10px;
  -moz-box-shadow: #e6e6e6 0px 0px 10px;
  box-shadow: #e6e6e6 0px 0px 10px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0.2rem 0;
}

.selectJzCard {
  width: 9rem;
  border-radius: 5px;
  border: none;
  background-image: linear-gradient(to right, #59fdc5, #00d48b);
  font-size: 0.4rem;
  position: absolute;
  bottom: 25%;
  left: 50%;
  transform: translateX(-50%);
}
.left-icon {
  width: 0.4rem;
  height: 0.4rem;
  position: relative;
  top: 2px;
}
</style>
